import { backendApi } from "../api";
import { docListTags } from "./utils";
import { ProjectListReturn } from "src/accurasee-backend-types/app/project/project.types";

const CACHE_TAG = "Projects";

const projectsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<{ data: ProjectListReturn[]; metadata: any }, any>(
      {
        query: (params) => ({ url: "/projects", params }),
        providesTags: docListTags(CACHE_TAG),
      },
    ),
    getProject: build.query({
      query: (id) => ({ url: `/projects/${id}` }),
      //providesTags: docGetTag(CACHE_TAG),
    }),
    getProjectExternalId: build.query<number[], any>({
      query: (params) => ({ url: `/projects/externalIds`, params }),
      transformResponse: (response) => response.data,
      providesTags: [CACHE_TAG],
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useGetProjectQuery,
  useGetProjectExternalIdQuery,
} = projectsApi;
