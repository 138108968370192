import _c from "src/constants/Constants";
import ActivityLogs from "src/components/Complex/ActivityLogs/ActivityLogs";
import AdditionalInfo from "src/components/Complex/Containers/AdditionalInfo";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonBack from "src/components/Basic/Simple/Buttons/ButtonBack";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonDeleteWithYesNoDialog from "src/components/Basic/Simple/Buttons/ButtonDeleteWithYesNoDialog";
import ButtonWithActionsDropdown from "src/components/Basic/Mixed/Buttons/ButtonWithActionsDropdown";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import CancelContractModal from "src/components/Complex/Modals/CancelContractModal";
import ContactPersonModal from "src/components/Complex/Modals/ContactPersonModal";
import ContractArticles from "src/components/Complex/Containers/ContractArticles";
import DialogContentWrapper from "src/components/Basic/Simple/Dialogs/DialogContentWrapper";
import getSelectOptions from "src/utils/getSelectOptions";
import MicrosoftDriveTable from "src/components/Basic/Mixed/Tables/MicrosoftDriveTable";
import PageWithTopPadding from "src/components/Basic/Mixed/Pages/PageWithTopPadding";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionFill from "src/components/Basic/Simple/Sections/SectionFill";
import SectionFloatingForButtons from "src/components/Basic/Simple/Sections/SectionFloatingForButtons";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useCreateSubContractOfferFromContract from "src/hooks/useCreateSubContractOfferFromContract";
import useFormContainer from "src/hooks/useFormContainer";
import useFormGlue from "src/hooks/useFormGlue";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useOnSubmit from "src/hooks/useOnSubmit";
import usePathPermission from "src/hooks/usePathPermission";
import useReload from "src/hooks/useReload";
import useTranslation from "src/hooks/useTranslationWrapper";
import useVatTypeTooltip from "src/hooks/useVatTypeTooltip";
import YesNoDialog from "src/components/Basic/Simple/Dialogs/YesNoDialog";
import { AdditionalInfoData } from "src/components/Complex/Containers/AdditionalInfoGetStructure";
import {
  Contract as _Contract,
  ContractStatus,
} from "src/accurasee-backend-types/app/contracts/contract.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { ContractExtended } from "src/utils/getDimensionItems";
import { escape } from "src/utils/translate";
import { IconTypes } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MicrosoftDriveContext } from "src/context/MicrosoftDriveProvider/MicrosoftDriveProvider";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContactPeopleQuery } from "src/redux/services/ContactPersonService";
import { useGetContractTypeQuery } from "src/redux/services/ContractTypesServices";
import { useGetCurrenciesQuery } from "src/redux/services/CurrencyService";
import { useGetCustomFieldsQuery } from "src/redux/services/CustomFieldService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useGetPlannedInvoicesQuery } from "src/redux/services/PlannedinvoiceService";
import { useLazyGetProjectsQuery } from "src/redux/services/ProjectService";
import { useGetTermsOfPaymentsQuery } from "src/redux/services/TermsofpaymentService";
import { useParams } from "react-router-dom";
import debounce from "lodash/debounce";

import {
  GetStructureExtraProps,
  ItemsTypes,
  getStructure,
  toData,
  toSubmitData,
} from "./ContractStructure";
import {
  useGetActiveIndicesQuery,
  useGetIndexQuery,
} from "src/redux/services/IndexService";
import {
  useDeleteContractMutation,
  useGetContractQuery,
  useGetContractTemplateDataQuery,
  usePatchContractMutation,
} from "src/redux/services/ContractService";
import { Types } from "mongoose";
import useConfirmLeave from "src/hooks/useConfirmLeave";
import isDisableSubmit from "src/utils/isDisableSubmit";

export default function Contract() {
  const { contractId } = useParams<{ contractId?: string }>();
  const pathPermissions = usePathPermission("/app/operations/customers");
  const { microsoftDriveClient } = useContext(MicrosoftDriveContext);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [openCancelContractModal, setOpenCancelContractModal] = useState(false);
  const [openYesNoDialog, setOpenYesNoDialog] = useState(false);
  const [isButtonDropdownSubmitting, setIsButtonDropdownSubmitting] =
    useState(false);

  const [t] = useTranslation();
  const { reloadKey, reload } = useReload();
  const { vatTypeTooltip, setVatTypeTooltipOnCustomer } = useVatTypeTooltip();

  const [deleteContract] = useDeleteContractMutation();
  const [patchContract] = usePatchContractMutation();

  const createSubContractOfferFromContract =
    useCreateSubContractOfferFromContract({ contractId });
  const { data: contract, isLoading: isLoadingContract } = useGetContractQuery(
    contractId,
    { skip: contractId === undefined },
  );

  const { data: customFieldsQuery, isLoading: isLoadingCustomFields } =
    useGetCustomFieldsQuery(undefined);
  const customFields =
    customFieldsQuery?.data?.filter(
      (customField) => customField.appliedToSections.includes("contract"), // custom fields for Contract only
    ) || [];
  // Structure customFieldsData
  const customFieldsData = customFields.reduce<Record<string, any>>(
    (acc, obj) => {
      acc[obj.name] = contract?.customFieldsData?.find(
        (i) => i.customFieldId === obj._id,
      )?.value;
      return acc;
    },
    {},
  );

  const { data: articles, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);

  const articleList = articles?.data;

  const { data: contractType, isLoading: isLoadingContractTypes } =
    useGetContractTypeQuery(contract?.contractTypeId, {
      skip: contract?.contractTypeId === undefined,
    });

  const { data: customersQuery, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersQuery?.data;

  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const [
    triggerGetProjects,
    {
      data: projects,
      isLoading: isLoadingProjects,
      isFetching: isFetchingProjects,
    },
  ] = useLazyGetProjectsQuery();

  const debounceGetProjects = useMemo(
    () =>
      debounce((search) => {
        console.log("debounceGetProjects");
        triggerGetProjects({ search: `"${search}"` });
      }, 500),
    [],
  );

  const handleProjectInputChange = useCallback(
    (input: string) => {
      debounceGetProjects.cancel();
      debounceGetProjects(input);
    },
    [debounceGetProjects],
  );

  const { data: plannedInvoices, isLoading: isLoadingPlannedInvoices } =
    useGetPlannedInvoicesQuery({ contractId });
  const plannedInvoicesCount = plannedInvoices?.metadata?.count || 0;

  const { data: termsOfPayments, isLoading: isLoadingTermsOfPayments } =
    useGetTermsOfPaymentsQuery(undefined);

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const { data: contactsRes, isLoading: isLoadingContacts } =
    useGetContactPeopleQuery(undefined);
  const contacts = contactsRes?.data || [];

  const { data: currenciesResponse, isLoading: isLoadingCurrencies } =
    useGetCurrenciesQuery(undefined);

  const currencies = currenciesResponse?.data || [];

  const { data: dataQueryIndices, isLoading: isLoadingIndices } =
    useGetActiveIndicesQuery(undefined);

  const indexOptions = getSelectOptions({
    data: dataQueryIndices?.data,
    label: (index) => `${index.name} (${index.value})`,
    value: (index) => String(index._id),
  });

  const { data: currentIndex, isLoading: isLoadingIndex } = useGetIndexQuery(
    contract?.indexOption?.indexId,
    { skip: !contract?.indexOption?.indexId },
  );

  if (
    !!currentIndex &&
    !indexOptions.map((v) => v.value).includes(String(currentIndex?._id))
  ) {
    indexOptions.push({
      label: `${currentIndex?.name} (${currentIndex?.value})`,
      value: String(currentIndex?._id),
      isActive: true,
    });
  }

  const {
    data: contractTemplateData,
    isLoading: isLoadingContractTemplateData,
  } = useGetContractTemplateDataQuery(contractId, {
    skip: contractId === undefined,
  });

  const isLoadingData =
    isLoadingArticles ||
    isLoadingContacts ||
    isLoadingContract ||
    isLoadingContractTemplateData ||
    isLoadingContractTypes ||
    isLoadingCurrencies ||
    isLoadingCustomers ||
    isLoadingCustomFields ||
    isLoadingDimensions ||
    isLoadingIndex ||
    isLoadingIndices ||
    isLoadingPlannedInvoices ||
    isLoadingProjects ||
    isLoadingTermsOfPayments ||
    isLoadingUsers;

  const initialFormData = toData({
    data: { ...contract, ...customFieldsData } as _Contract,
  });

  const initialFormDataAdditionalInfo: AdditionalInfoData = {
    description: contract?.description,
    remarks: contract?.remarks,
    _id: contract?._id,
  };

  const {
    formData,
    hasTriedToSubmit,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<ContractExtended, GetStructureExtraProps, ItemsTypes>({
    getStructure,
    extraProps: {
      contacts,
      contractType,
      currencies,
      currentIndex,
      customers,
      customFields,
      dimensions,
      indexOptions,
      isContactModalOpen,
      plannedInvoicesCount,
      projects: projects?.data,
      setIsContactModalOpen,
      setVatTypeTooltipOnCustomer,
      t,
      termsOfPayments,
      users,
      vatTypeTooltip,
      handleProjectInputChange,
      isFetchingProjects,
    },
    isLoading: isLoadingData,
    initialFormData,
  });

  const formGlueAdditionalInfo = useFormGlue<AdditionalInfoData>();
  const formGlueArticles = useFormGlue<ContractArticle>();

  const data = {
    ...toSubmitData({
      data: formData,
      initData: initialFormData,
      customFields,
    }),
    ...formGlueAdditionalInfo.submitData,
    contractArticles: formGlueArticles.submitData,
  };
  const isServiceOrderContract =
    !!formData?.contractFeatures?.serviceOrderContract;

  const initialData = {
    ...toSubmitData({
      data: initialFormData,
      initData: initialFormData,
      customFields,
    }),
    contractArticles: formGlueArticles.submitDataInitial,
  };

  useEffect(() => {
    if (currentIndex?.active === false && !hasTriedToSubmit) {
      setHasTriedToSubmit(true);
    }
  }, [currentIndex, hasTriedToSubmit]);

  useEffect(() => {
    triggerGetProjects({
      search: `"${contract?.projectExternalId} ${contract?.projectName}"`,
    });
  }, [contract?.projectExternalId, contract?.projectName]);

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { update: patchContract, delete: deleteContract },
      data: {
        update: data,
        delete: [contractId],
      },
      dataId: contractId,
      name: "Contract",
      rerouteUrlOnSuccess: {
        delete: `/app/contracts`, // NOTE: No trailing slash
      },
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      formGlueAdditionalInfo.setHasTriedToSubmit(false);
      formGlueArticles.setHasTriedToSubmit(false);
    },
  });

  const onSubmit = useOnSubmit({
    apiMutations: { update: patchContract },
    name: "Change project status",
  });
  const onSubmitCustom = async ({
    status,
    contractId,
  }: {
    status: ContractStatus;
    contractId: string;
  }) => {
    setIsButtonDropdownSubmitting(true);
    await onSubmit({
      data: { update: { status } },
      dataId: contractId,
    });

    setIsButtonDropdownSubmitting(false);
  };

  const commonPropsBuildItem = {
    data: formData,
    helperText,
    disabled: formData?.status === "locked",
  };

  const isValidUpdate = isFormValid && formGlueArticles.isFormValid;

  useConfirmLeave({
    when: !isDisableSubmit({
      data,
      initialData,
      isValid: isValidUpdate,
    }),
  });

  return (
    <>
      <PageWithTopPadding
        label={"Edit contract details"}
        breadcrumbs={[
          { label: "Contracts", link: "/app/contracts" },
          {
            label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          },
          { label: "Overview" },
        ]}
        isLoading={isLoadingData}
        boxRight={
          contract?.contractOfferFamilyId ? (
            <Link
              component={RouterLink}
              to={`/app/contract-offers/${contract?.contractOfferId?.groupingAll}?familyId=${contract.contractOfferFamilyId}&contractId=${contractId}`}
            >
              {t("Show all related offers")}
            </Link>
          ) : (
            <></>
          )
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (plannedInvoicesCount > 0) {
              setOpenYesNoDialog(true);
            } else {
              onFormSubmit({ action: "update" }).catch((err) =>
                console.error(err),
              );
            }
          }}
          ref={refForm}
        >
          <SectionContainer>
            <Section
              key={"contract-info"}
              label={"Contract info"}
              md={12}
              lg={4}
            >
              {BuildItems({
                items: structure.items.filter(
                  (item) =>
                    item.itemType === "info" ||
                    (item.itemType === "attachment" && !microsoftDriveClient),
                ),
                ...commonPropsBuildItem,
              })}
            </Section>
            <SectionFill
              id={"time-and-terms"}
              key={"time-and-terms"}
              label={"Time and terms"}
              md={12}
              lg={4}
            >
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === "time",
                ),
                ...commonPropsBuildItem,
              })}
            </SectionFill>
            {!isServiceOrderContract && (
              <Section key={"customer"} label={"Customer"} md={12} lg={4}>
                {BuildItems({
                  items: structure.items.filter(
                    (item) => item.itemType === "customer",
                  ),
                  ...commonPropsBuildItem,
                })}
              </Section>
            )}
            {formData?.projectExternalId && microsoftDriveClient ? (
              <MicrosoftDriveTable
                activityLogGroupId={contractId as unknown as Types.ObjectId}
                collectionName={"contracts"}
                templateData={contractTemplateData}
                leafFolder={formData.projectExternalId}
                prefixDocumentName={formData.projectExternalId}
                showTemplateButton
              />
            ) : (
              <></>
            )}

            <AdditionalInfo
              formGlue={formGlueAdditionalInfo}
              invoicePlanInit={initialFormDataAdditionalInfo}
              type="contract"
              useExpand
            />

            <ContractArticles
              articleList={articleList}
              contractArticlesInit={contract?.contractArticles}
              contractId={contractId}
              currencyCode={contract?.currencyCode}
              editRights={contract?.permissions?.updateRights || false}
              formGlue={formGlueArticles}
              isIndex={!!formData?.indexOption?.index}
              key={reloadKey}
              useDownStreamPropagation={true}
            />
            <ActivityLogs
              groupId={contractId as unknown as Types.ObjectId}
              label={"document activity log"}
              type={"microsoft_drive_file"}
            />
          </SectionContainer>
        </form>
        {/* Floating Button Wrapper as a Footer*/}
        <SectionFloatingForButtons isFullWidth>
          <SectionWithButtonContainer sx={{ margin: 0 }}>
            <ButtonGroup>
              <ButtonBack />
              <ButtonSecondary
                disableRipple
                onClick={() => {
                  setOpenCancelContractModal(true);
                }}
                disabled={
                  contract?.status === "cancelled" ||
                  contract?.status === "finished" ||
                  !contract?.permissions.updateRights
                }
                id={"cancel-contract"}
              >
                {t("cancel contract")}
              </ButtonSecondary>
              <ButtonPrimary
                onClick={() =>
                  createSubContractOfferFromContract().catch((e) =>
                    console.error(e),
                  )
                }
              >
                {t("create sub contract offer")}
              </ButtonPrimary>
              {contractId &&
                // This is governed by the permission "update" - "all" contract
                contract?.permissions.updateRights &&
                contract.permissions?.filter === "all" && (
                  <ButtonWithActionsDropdown
                    arrowDirection="up"
                    actionOptions={(
                      _c.COMPLETION_CONTRACT_STATUSES as Partial<ContractStatus>[]
                    ).map((status) => {
                      // Reopen means that resets status to “invoiced”
                      // and only possible if status is Locked/Finished/Archived
                      const mappedStatus =
                        status === "invoiced" ? "reopen" : status;
                      return {
                        disabled:
                          status === contract?.status ||
                          (status === "invoiced" &&
                            !_c.REOPENABLE_CONTRACT_STATUSES.includes(
                              contract?.status as Partial<ContractStatus>,
                            )),
                        iconType: mappedStatus as IconTypes,
                        label: mappedStatus,
                        onClick: () => {
                          onSubmitCustom({ status, contractId });
                        },
                      };
                    })}
                    isColor
                    isLoading={isButtonDropdownSubmitting}
                    label="Change Project Status"
                  />
                )}
            </ButtonGroup>
            <ButtonGroup>
              <ButtonClear
                onClick={() => {
                  reload();
                  setFormData(initialFormData);
                }}
              />
              <ButtonDeleteWithYesNoDialog
                dialogContent={t(
                  `Are you sure you want to delete this contract?`,
                )}
                onSubmit={() => {
                  onFormSubmit({ action: "delete" }).catch((err) =>
                    console.error(err),
                  );
                }}
              />
              <ButtonUpdate
                id={"edit-contract"}
                initialSubmitData={initialData}
                isValid={isValidUpdate}
                isSubmitting={isSubmitting}
                onSubmit={() => {
                  setHasTriedToSubmit(true);
                  formGlueAdditionalInfo.setHasTriedToSubmit(true);
                  formGlueArticles.setHasTriedToSubmit(true);
                  refForm.current.requestSubmit();
                }}
                permissions={contract?.permissions}
                submitData={data}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </SectionFloatingForButtons>
      </PageWithTopPadding>

      <YesNoDialog
        open={openYesNoDialog}
        onYes={() => {
          setOpenYesNoDialog(false);
          onFormSubmit({ action: "update" }).catch((err) => console.error(err));
        }}
        onNo={() => setOpenYesNoDialog(false)}
      >
        <DialogContentWrapper
          contentType="alert"
          title="Are you sure you want to update this contract?"
          message="These changes will affect all active invoice plans and all planned invoices"
        />
      </YesNoDialog>
      <CancelContractModal
        openModal={openCancelContractModal}
        handleCloseModal={() => setOpenCancelContractModal(false)}
        contractId={String(contract?._id)}
        contract={formData}
        setData={setFormData}
      />

      {/* Add Contact Modal */}
      <ContactPersonModal
        customerId={formData?.customerId}
        openModal={isContactModalOpen && !!pathPermissions?.writeRights}
        setOpenModal={setIsContactModalOpen}
        postCreate={(resData) => {
          // To show Customer info to the form
          if (!!resData && formData) {
            const state = {
              ...formData,
              contactPersonId: resData._id,
              yourReference: `${resData?.firstName} ${resData?.lastName}`,
              yourReferenceEmail: resData?.email,
            };
            setFormData(state);
          }
        }}
      />
    </>
  );
}
